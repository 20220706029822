import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 448.000000 557.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,557.000000) scale(0.100000,-0.100000)">
		<path d="M2088 1260 c-89 -22 -152 -93 -164 -184 -9 -60 14 -108 68 -144 35
-23 52 -27 107 -27 36 1 83 7 103 14 31 10 39 19 47 49 17 70 21 103 11 97 -6
-3 -10 0 -10 9 0 10 -10 14 -31 13 -29 -2 -33 -6 -46 -49 -9 -32 -19 -49 -31
-50 -93 -8 -136 53 -96 137 17 36 29 43 85 55 26 5 46 2 70 -10 32 -17 34 -17
66 4 18 11 32 27 31 35 -7 39 -135 70 -210 51z"/>
<path d="M2870 1261 c-49 -10 -86 -36 -105 -77 -31 -65 -6 -106 80 -134 22 -7
50 -19 63 -27 56 -35 -39 -58 -120 -29 -35 13 -38 12 -52 -9 -29 -45 -25 -59
24 -72 106 -27 209 -7 246 50 33 49 36 69 15 103 -16 26 -31 35 -86 49 -71 20
-90 39 -60 61 24 18 67 18 110 0 33 -14 34 -14 50 20 20 42 15 50 -33 64 -41
11 -79 11 -132 1z"/>
<path d="M705 1248 c-5 -14 -45 -198 -50 -233 -2 -11 -8 -39 -13 -62 l-10 -43
123 0 c83 0 133 5 156 14 85 36 129 100 129 191 0 51 -3 58 -38 93 -45 45 -73
52 -205 52 -61 0 -89 -4 -92 -12z m188 -79 c31 -17 37 -28 37 -77 0 -36 -5
-49 -31 -73 -26 -25 -39 -29 -85 -29 -30 0 -54 3 -54 8 0 16 30 165 36 173 8
13 71 11 97 -2z"/>
<path d="M1132 1228 c-6 -18 -18 -73 -28 -123 -16 -83 -16 -93 -1 -128 36 -83
191 -103 269 -34 39 33 59 82 80 187 10 52 21 103 24 113 5 15 -2 17 -45 17
-60 0 -63 -5 -80 -105 -7 -38 -16 -87 -22 -108 -12 -45 -46 -71 -83 -61 -46
11 -49 34 -27 153 12 59 21 111 21 114 0 4 -22 7 -49 7 -47 0 -50 -2 -59 -32z"/>
<path d="M1537 1188 c-9 -40 -23 -111 -32 -158 -9 -47 -17 -93 -19 -102 -4
-15 3 -18 44 -18 46 0 49 2 59 33 5 17 12 50 16 72 11 74 19 72 76 -19 54 -84
54 -85 96 -86 23 0 45 5 49 11 7 13 63 293 64 322 0 14 -9 17 -49 17 l-49 0
-7 -42 c-10 -62 -27 -118 -36 -118 -5 0 -32 36 -60 80 -52 79 -53 80 -94 80
l-42 0 -16 -72z"/>
<path d="M2375 1248 c-16 -51 -45 -218 -41 -238 2 -14 5 -28 5 -32 1 -4 14
-21 30 -37 53 -54 174 -54 237 2 48 41 62 73 84 186 11 53 22 105 26 114 5 15
-1 17 -49 17 l-54 0 -13 -62 c-32 -165 -48 -203 -87 -211 -69 -13 -83 27 -54
161 12 54 21 101 21 105 0 13 -100 8 -105 -5z"/>
<path d="M3131 1233 c-6 -16 -13 -44 -16 -63 -3 -19 -10 -53 -15 -75 -21 -90
-1 -155 53 -175 114 -43 213 -10 264 87 11 21 53 210 53 238 0 8 -17 12 -52
11 l-52 -1 -18 -100 c-19 -111 -40 -161 -70 -168 -31 -8 -67 10 -73 36 -6 21
11 132 31 212 6 24 5 25 -44 25 -45 0 -51 -3 -61 -27z"/>
<path d="M3532 1173 c-31 -138 -45 -221 -38 -238 3 -9 2 -13 -4 -10 -6 3 -10
1 -10 -4 0 -14 232 -15 281 -1 41 12 74 44 83 82 4 20 0 34 -20 57 l-26 31 31
29 c38 35 40 70 7 108 -24 28 -26 28 -154 31 l-130 4 -20 -89z m202 11 c19 -7
21 -35 4 -52 -7 -7 -34 -12 -60 -12 -46 0 -48 1 -48 29 0 16 6 32 13 34 18 8
73 8 91 1z m-6 -139 c17 -6 15 -30 -4 -49 -20 -20 -112 -22 -120 -3 -3 8 0 25
5 39 9 24 13 25 58 21 26 -2 54 -6 61 -8z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
